import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="landing-container">
      <div className="container">
        <div className="header">
          <h1>
            Sorry, there's nothing here. Please check with your code provider
            for a valid link.
          </h1>
        </div>
      </div>
      {/* <Image imagePath="gatsby-astronaut.png" /> */}
    </div>
  </Layout>
)

export default IndexPage
